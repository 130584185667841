/* You can add global styles to this file, and also import other style files */

@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import '~bootstrap/scss/bootstrap';
@import "assets/scss/ngZorro.scss";
@import "assets/scss/_colors.scss";
@import "assets/scss/dashboard.scss";
:root {
    --primary-color: #2f5f9f;
    --secondary-color: #636363;
}

body {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 500;
    background: rgb(246, 246, 246);
    &.bg-gray {
        background: #f7f7f7;
    }
}

.pt-6 {
    padding-top: 100px;
}

#toTopBtn {
    i {
        color: var(--primary-color);
    }
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    font-size: 3em;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 15px;
    border-radius: 100%;
    line-height: 30px;
}

.site-page-header {
    border-bottom: 3px solid #2f5f9fb8;
    margin-bottom: 1rem;
    .ant-page-header-heading-title {
        font-size: 1.7rem;
    }
    .ant-page-header-back i {
        font-size: 1.4rem;
        margin-top: 5px;
    }
    .ant-page-header-heading-title,
    .ant-page-header-back i {
        color: var(--primary-color);
    }
    .ant-page-header-heading-sub-title {
        color: #6d6d6d;
        font-size: 16px;
    }
}

.filters {
    padding: 0 5rem !important;
}

.titleFilterHeader {
    background: rgb(246, 246, 246);
}

.titleFilterHeader .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0;
    color: #616163;
    justify-content: end;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        padding-top: 1px;
    }
    i {
        font-size: 1.2rem;
        margin-right: 2px;
    }
}

.titleFilterHeader .ant-collapse .ant-collapse-content-box {
    padding: 16px 0;
}

.ant-btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.filtersPanel .ant-collapse-item > div.ant-collapse-header,
.filtersPanel .ant-collapse-item > div.ant-collapse-header > div {
     display: block;
     justify-content: right;
}
.filtersPanel .filtersToggleButton {
    height: auto;
    width: auto;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--primary-color);
    border-color: var(--primary-color);
}

.ant-input-focused, .ant-input:focus, .ant-input:hover {
    border-color: var(--primary-color);
}
.ant-picker-calendar-header, .ant-fullcalendar-header, .ant-picker-calendar-mini .ant-picker-calendar-header{
    display: none !important;
}
.ant-picker-calendar .ant-picker-panel{
    border-top: none;
}
#mainDashboardCalendar .ant-picker-calendar-mini .ant-picker-calendar-date-content span {
    position: absolute !important;
    top: -8px !important;
    right: -3px !important;
    font-size: 8px !important;
    color: red !important;
}