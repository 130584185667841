.panel {
    margin: 1% 0;
    box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
}

// card dashboard
nz-collapse.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-size: 1.2rem;
    font-weight: 600;
}

nz-collapse.ant-collapse {
    border-radius: 5px;
}

.ant-table.ant-table-small {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

// colori per righe e dettagli tabelle card dashboard
.red {
    background-color: $red;
    color: var(--primary-color);
}

.green {
    background-color: $green;
    color: var(--primary-color);
}

.orange {
    background-color: $orange;
    color: #fff;
}

.pink {
    background-color: $pink;
    color: #fff;
}

.blue {
    background-color: $blue;
    color: #fff;
}

.white {
    background-color: white;
    color: var(--primary-color);
}

.ant-table-thead>tr>th {
    background-color: var(--primary-color);
    color: #fff;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #18181826;
    cursor: pointer;
}

.dettaglioScadenza {
    padding: 3px;
    border-radius: 6px;
}

.ant-table-thead>tr>th {
    font-size: 12px;
}

td#stato,
.font12 {
    font-size: 12px;
}

// calendar
a.fc-daygrid-day-number,
a.fc-col-header-cell-cushion {
    text-decoration: none;
}

a.fc-col-header-cell-cushion,
a.fc-daygrid-day-number {
    color: black;
}

.fc-event-title.fc-sticky {
    font-weight: 600;
    font-size: 16px;
}

.fc-direction-ltr {
    text-align: right !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 6px;
}