.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 500;
    .ant-collapse-arrow {
        vertical-align: 1px;
    }
}

.ant-message-custom-content {
    align-items: center;
    display: flex;
}
.ant-message-notice-content {
    background: #fff2f0;
    border: 1px solid #ffccc7;
}
nz-select {
    margin: 0 8px 10px 0;
    width: 120px;
}

.ant-table-wrapper.dataView {
    padding: 0 5rem;
}
.ant-radio-button-wrapper{
    color: #d9d9d9;
}
.ant-spin{
    font-size: 5rem;
    color: #54ffff;
}
.anticon{
    vertical-align: middle;
}

